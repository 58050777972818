// Register the service worker.
self.addEventListener('install', function(event) {
  event.waitUntil(
    caches.open('my-cache').then(function(cache) {
      return cache.addAll([
        './index.html',
        './style.css',
        './ap.js',
        './nasalization.woff2',
      ]);
    })
  );
});

// Activate the new service worker immediately.
self.addEventListener('activate', function(event) {
  event.waitUntil(self.clients.claim());
});

// Intercept network requests and serve cached responses if available.
self.addEventListener('fetch', function(event) {
  event.respondWith(
    caches.match(event.request).then(function(response) {
      return response || fetch(event.request);
    })
  );
});
